.banner-container {
  width: 100%;
  height: 155px; /* تحديد ارتفاع البنر */
  overflow: hidden; /* للتأكد من عدم تجاوز محتوى البنر للحدود */
  position: relative; /* لإضافة محتوى داخل الحاوية */
  touch-action: pan-y; /* لتعطيل التمرير الأفقي */
  outline: none; /* إزالة أي تأثيرات التركيز */
  -webkit-tap-highlight-color: transparent; /* إزالة تأثير الضغط في الأجهزة التي تعمل باللمس */
  padding-bottom: 10px;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 145px; /* يجب أن يكون نفس ارتفاع .banner-container */
  box-sizing: border-box;
}

.slick-slide img {
  display: block;
  width: 100%;
  height: 100%; /* لضبط ارتفاع الصورة لتناسب ارتفاع الحاوية */
  object-fit: cover; /* لضمان تغطية الصورة بالكامل داخل الحاوية */
  /* padding: 0 10px; لإضافة مسافة على الجانبين */
  /* border-radius: 15px; حواف منحنية للصورة */
}

.slick-dots {
  position: absolute;
  bottom: 10px; /* مسافة من أسفل البنر */
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  
}

.slick-dots li {
  margin: 0 5px; /* مسافة بين النقاط */
}

.slick-dots li button:before {
  font-size: 10px; /* حجم النقاط */
  color: rgb(9, 167, 173); /* لون النقاط */
}


.search-container {
  outline: 1px solid #ccc; /* استعادة تأثير التركيز لعنصر البحث */
  border: 1px solid #ccc; /* استعادة الحدود لعنصر البحث */
}


/* تحديد التأثيرات فقط للبّنر */
.banner-container * {
  outline: none; /* إزالة تأثير التركيز من العناصر داخل البنر */
  border: none; /* إزالة أي حدود من العناصر داخل البنر */
  -webkit-tap-highlight-color: transparent; /* إزالة تأثير الضغط في الأجهزة التي تعمل باللمس */
}
