@tailwind base;
@tailwind components;
@tailwind utilities;

.delivery {
  height: 100% !important;
}

/* Google Map */
.gm-bundled-control-on-bottom {
  position: static !important;
  margin: 0 !important;
}
.gmnoprint {
  left: 1rem !important;
  top: 5rem !important;
  padding: 0 !important;
}
.gmnoprint > div {
  width: 35px !important;
  border-radius: 999999px !important;
  display: flex;
  flex-direction: column;
}
.gmnoprint > div button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 35px !important;
}
.gmnoprint > div img {
  width: 10px !important;
  height: 10px !important;
}

/* Input Animation */
input[type='text']:focus ~ .input-text {
  @apply text-main-900 -translate-y-5 translate-x-2 scale-75;
}

/* leaflet */
@import url('~leaflet/dist/leaflet.css');
.leaflet-container {
  width: 100%;
  height: 300px;
}
.leaflet-control-geocoder.leaflet-bar.leaflet-control.leaflet-control-geocoder-expanded.leaflet-control-geocoder-options-open {
  border-radius: 5px !important;
}
.leaflet-control-geocoder.leaflet-bar,
.leaflet-control-geocoder-icon {
  border-radius: 999px !important;
}
.leaflet-control-geocoder-icon {
  opacity: 0.6;
}
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  border-radius: 10px 10px 0 0 !important;
}
.leaflet-top.leaflet-right {
  left: 0 !important;
  right: auto;
}
.leaflet-top.leaflet-left {
  top: 25%;
  transform: translateY(-75%);
}
.leaflet-control-zoom.leaflet-bar.leaflet-control,
.leaflet-control-geocoder.leaflet-bar.leaflet-control {
  border: none !important;
}
.leaflet-control-geocoder.leaflet-bar.leaflet-control {
  margin-right: 0;
  margin-left: 10px;
}
.leaflet-control-zoom-out {
  border-radius: 0 0 10px 10px !important;
}
.leaflet-control-zoom-in span,
.leaflet-control-zoom-out span {
  opacity: 0.6;
}
.target {
  position: absolute;
  top: 35%;
  transform: translateY(-65%);
  left: 0;
  margin-left: 10px;
  margin-top: 10px;
  z-index: 400;
  padding: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}

* {
  touch-action: pan-y pan-x !important;
  -webkit-user-select: none;
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer base {
  body {
    @apply dark:bg-gray-900;
  }
}

@layer components {
  .swiper {
    @apply dark:bg-gray-700;
  }
  .my-custom-pagination {
    @apply py-4 px-0 gap-2 pr-2 overflow-x-auto dark:bg-gray-700 whitespace-nowrap w-full sticky z-[50] inset-0 bg-white;
  }
  .delivery-pagination {
    @apply py-4 flex justify-center px-0 gap-2 pr-2 overflow-x-auto dark:bg-gray-700 whitespace-nowrap w-full sticky z-[50] inset-0 bg-white;
  }
  .my-custom-pagination > span,
  .delivery-pagination > span {
    @apply bg-gray-100 dark:bg-gray-900 dark:text-white;
  }
  .swiper-pagination-bullet-active {
    @apply !text-white !bg-main-900;
  }
}
.my-custom-pagination {
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
}
.swiper-pagination-bullet {
  align-items: center !important;
  display: inline-flex !important;
  font-weight: bold !important;
  height: 40px !important;
  justify-content: center !important;
  width: 80px !important;
  border-radius: 10000px !important;
  flex-direction: row-reverse;
  gap: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  opacity: 1 !important;
  font-size: 0.82rem;
  line-height: 1rem;
  font-weight: 600;
  margin: 0 0 0 0.5rem !important;
  padding: 0.7rem !important;
  height: 2.5rem !important;
  width: auto !important;
  white-space: nowrap;
}

.animateItems {
  animation: up 0.4s linear;
}
.fastAnimate {
  animation: upFast 0.5s ease-out;
}
.fastAnimate {
  animation: upFast 0.5s ease-out;
}

@keyframes up {
  from {
    transform: translateY(50%);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes upFast {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0);
  }
}

.eax {
  margin-left: 17px;
  margin-top: 17px;
  cursor: pointer;
  height: 24px !important;
  width: 24px !important;
  transition: all 0.3s ease;
}
.eax2 {
  cursor: pointer;
  height: 24px !important;
  width: 24px !important;
  transition: all 0.3s ease;
}

body,
input,
textarea,
button,
select,
div,
.eax,
.class,
p,
a {
  -webkit-tap-highlight-color: transparent;
}

.rounded-md {
  border-radius: 20px;
}

.rounded-sid {
  border-radius: 20px 0px 0px 0px !important;
}
.bg-primaryGreen-300 {
  border-radius: 20px 0px 0px 0px !important;
}

.bg-black {
  backdrop-filter: blur(3px) saturate(80%);
}

.pl-2 {
  padding-left: 2rem;
}

.-right-2 {
  right: -0.6rem;
  top: -0.2rem;
}
.icon-flip {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}

.foa9l {
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  padding: 5px;
}

.dark body {
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  display: none;
}
html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}

/* استايل السوشل ميديا في القائمه الجانبية */
.flex2 {
  display: flex;
  overflow-x: auto; /* يتيح التمرير الأفقي */
  white-space: nowrap; /* يمنع الأسطر المتعددة */
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.5rem; /* المسافة بين النص والسهم */
  padding: 0.5rem;
  background-color: black;
  color: white;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  transition: opacity 0.3s;
}

a:hover + .tooltip,
a:focus + .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: -0.5rem; /* تعديل المسافة حسب الحاجة */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid black;
}
/*  نهاية الاستايل السوشل ميديا في القائمه الجانبية */